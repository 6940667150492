import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'next-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode } from 'swiper'

// Contants
import { POPULAR_ZONE_TYPE, LAYOUT, SIZE } from '../../index'

// Components
import Icon from '@/assets/icons'
import PopularZoneBrandItem from '../PopularZoneBrandItem'
import PopularZoneEventItem from '../PopularZoneEventItem'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

interface IPoplarZoneDesktopProps {
  type: string
  data: any
}

const PopularZoneDesktop = (props: IPoplarZoneDesktopProps) => {
  const { type, data } = props
  const { t } = useTranslation()
  const firstEventProduct = data[0]
  const restEventProducts = data.slice(1, 7)

  const getBrandSlideData = () => {
    const chunkSize = 2
    const result = []

    for (let i = 0; i < data.length; i += chunkSize) {
      result.push(data.slice(i, i + chunkSize))
    }

    return result
  }

  return (
    <div
      className={cx('popular-zone-desktop', {
        'popular-zone-desktop--brand': type === POPULAR_ZONE_TYPE.BRAND,
        'popular-zone-desktop--event': type === POPULAR_ZONE_TYPE.EVENT,
      })}
    >
      <div className={cx('popular-zone-desktop__header')}>
        <div className={cx('popular-zone-desktop__icon')}>
          {type === POPULAR_ZONE_TYPE.BRAND ? <Icon.PopularZoneIconBrand /> : <Icon.PopularZoneIconProduct />}
        </div>
        <div className={cx('popular-zone-desktop__title')}>
          {type === POPULAR_ZONE_TYPE.BRAND ? t('Choice_Merchant_Title') : t('Popular_Product_Title')}
        </div>
      </div>
      <div className={cx('popular-zone-desktop__content')}>
        {type === POPULAR_ZONE_TYPE.BRAND ? (
          <div className={cx('popular-zone-desktop__brand')}>
            <Swiper
              className={cx('popular-zone-desktop__brand-list')}
              slidesPerView={'auto'}
              spaceBetween={20}
              allowTouchMove={true}
              navigation={{
                prevEl: '.navigation-btn-prev',
                nextEl: '.navigation-btn-next',
              }}
              modules={[Navigation, FreeMode]}
              freeMode={true}
            >
              {getBrandSlideData().map((brandSlideItem: any, brandSlideIndex: number) => (
                <SwiperSlide key={brandSlideIndex}>
                  <div className={cx('popular-zone-desktop__brand-slide')}>
                    {brandSlideItem.map((brandItem: any, brandIndex: number) => (
                      <PopularZoneBrandItem key={brandIndex} data={brandItem} />
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className={cx('navigation-btn', 'navigation-btn-prev')}>
              <Icon.ArrowLeftWithoutSize />
            </div>
            <div className={cx('navigation-btn', 'navigation-btn-next')}>
              <Icon.ArrowRight />
            </div>
          </div>
        ) : (
          <div className={cx('popular-zone-desktop__event')}>
            <div className={cx('popular-zone-desktop__event-left')}>
              <PopularZoneEventItem data={firstEventProduct} layout={LAYOUT.VERTICAL} size={SIZE.LARGE} best_selling />
            </div>
            <div className={cx('popular-zone-desktop__event-right')}>
              {restEventProducts.map((item: any, index: number) => (
                <PopularZoneEventItem key={index} data={item} layout={LAYOUT.HORIZONTAL} size={SIZE.SMALL} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PopularZoneDesktop
