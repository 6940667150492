import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

// Libs
import { fetchLiveInfo } from '@/api/fetchLiveInfo'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

// Types

interface LiveDataProps {
  productId?: any
  merchantId?: any
}

export const useLiveData = ({ productId = '0', merchantId = '0' }: LiveDataProps) => {
  const [isLive, setIsLive] = useState(false)
  const [isMerchantLive, setIsMerchantLive] = useState(false)
  const [roomId, setRoomId] = useState('')

  const { data: liveData, isFetched: isLiveDataFetched, isSuccess: isSuccess } = useQuery([QUERY_KEY.FETCH_LIVE_INFO], () => fetchLiveInfo(), {})

  useEffect(() => {
    if (isLiveDataFetched) {
      const productLiveData = liveData?.productMap[productId]
      const merchantLiveData = liveData?.merchantMap[merchantId]

      setIsLive(!!productLiveData)
      setIsMerchantLive(!!merchantLiveData)
      // TODO: 不確定如果productLiveData和merchantLiveData都有值要怎麼處理
      if (productLiveData) {
        setRoomId(productLiveData?.roomId)
      } else if (merchantLiveData) {
        setRoomId(merchantLiveData?.roomId)
      }
    }
  }, [isLiveDataFetched, merchantId, productId])

  return {
    liveData: liveData,
    isLive,
    isMerchantLive,
    roomId,
    isSuccess,
    liveProductInfo: liveData?.productMap[productId],
    liveMerchantInfo: liveData?.merchantMap[merchantId],
  }
}
