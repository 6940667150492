import React from 'react'
import classnames from 'classnames/bind'
import useMedia from '@/hooks/useMedia'

// Components
import PopularZoneDesktop from './components/PopularZoneDesktop'
import PopularZoneMobile from './components/PopularZoneMobile'

import { useRecommendMerchants } from './hooks/useRecommendMerchants'
import { usePopularProducts } from './hooks/usePopularProducts'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const POPULAR_ZONE_TYPE = {
  BRAND: 'brand',
  EVENT: 'event',
}

export const LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

export const SIZE = {
  LARGE: 'large',
  EXTRA_MEDIUM: 'extra-medium',
  MEDIUM: 'medium',
  SMALL: 'small',
}

const PopularZone = () => {
  const { isDesktop } = useMedia()
  const { recommendMerchantsData, isRecommendMerchantsDataSuccess } = useRecommendMerchants()
  const { popularProductsData, isPopularProductsDataSuccess } = usePopularProducts({ params: { event: 'home_hot_product' } })

  return (
    <div className={cx('popular-zone')}>
      {isDesktop ? (
        <>
          {isRecommendMerchantsDataSuccess && <PopularZoneDesktop type={POPULAR_ZONE_TYPE.BRAND} data={recommendMerchantsData || []} />}
          {isPopularProductsDataSuccess && <PopularZoneDesktop type={POPULAR_ZONE_TYPE.EVENT} data={popularProductsData?.productsInfo || []} />}
        </>
      ) : (
        <>
          {isRecommendMerchantsDataSuccess && <PopularZoneMobile type={POPULAR_ZONE_TYPE.BRAND} data={recommendMerchantsData || []} />}
          {isPopularProductsDataSuccess && <PopularZoneMobile type={POPULAR_ZONE_TYPE.EVENT} data={popularProductsData?.productsInfo || []} />}
        </>
      )}
    </div>
  )
}

export default PopularZone
