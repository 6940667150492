import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

class FetchLiveInfoApi extends Service {
  constructor() {
    super()
    this.name = 'FETCH_LIVE_INFO'

    this.config = {
      url: `/v1/live/merchant-product`,
      method: 'GET',
    }
  }
}

export const fetchLiveInfo = () => getCallApiFunction(new FetchLiveInfoApi())
