import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'next-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode } from 'swiper'

// Contants
import { POPULAR_ZONE_TYPE, LAYOUT, SIZE } from '../../index'

// Components
import Icon from '@/assets/icons'
import PopularZoneBrandItem from '../PopularZoneBrandItem'
import PopularZoneEventItem from '../PopularZoneEventItem'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

interface IPoplarZoneMobileProps {
  type: string
  data: any
}

const PopularZoneMobile = (props: IPoplarZoneMobileProps) => {
  const { type, data } = props
  const { t } = useTranslation()

  return (
    <div
      className={cx('popular-zone-mobile', {
        'popular-zone-mobile--brand': type === POPULAR_ZONE_TYPE.BRAND,
        'popular-zone-mobile--event': type === POPULAR_ZONE_TYPE.EVENT,
      })}
    >
      <div className={cx('popular-zone-mobile__header')}>
        <div className={cx('popular-zone-mobile__icon')}>
          {type === POPULAR_ZONE_TYPE.BRAND ? <Icon.PopularZoneIconBrand /> : <Icon.PopularZoneIconProduct />}
        </div>
        <div className={cx('popular-zone-mobile__title')}>
          {type === POPULAR_ZONE_TYPE.BRAND ? t('Choice_Merchant_Title') : t('Popular_Product_Title')}
        </div>
      </div>
      <div className={cx('popular-zone-mobile__content')}>
        {type === POPULAR_ZONE_TYPE.BRAND ? (
          <React.Fragment key={'brand'}>
            <Swiper
              className={cx('popular-zone-mobile__brand-list')}
              slidesPerView={'auto'}
              spaceBetween={10}
              allowTouchMove={true}
              navigation={{
                prevEl: '.navigation-btn-prev__brand',
                nextEl: '.navigation-btn-next__brand',
              }}
              modules={[Navigation, FreeMode]}
              freeMode={true}
              slideToClickedSlide
              grabCursor
            >
              {data.map((item: any) => (
                <SwiperSlide key={item?.id} style={{ width: 'auto' }}>
                  <PopularZoneBrandItem data={item} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={cx('navigation-btn', 'navigation-btn-prev__brand')}>
              <Icon.ArrowLeftWithoutSize />
            </div>
            <div className={cx('navigation-btn', 'navigation-btn-next__brand')}>
              <Icon.ArrowRight />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment key={'event'}>
            <Swiper
              className={cx('popular-zone-mobile__event-list')}
              slidesPerView={'auto'}
              spaceBetween={10}
              allowTouchMove={true}
              navigation={{
                prevEl: '.navigation-btn-prev__event',
                nextEl: '.navigation-btn-next__event',
              }}
              modules={[Navigation, FreeMode]}
              freeMode={true}
              slideToClickedSlide
              grabCursor
            >
              <SwiperSlide key={data[0]?.id} style={{ width: 'auto' }}>
                <PopularZoneEventItem data={data[0]} layout={LAYOUT.VERTICAL} size={SIZE.MEDIUM} best_selling />
              </SwiperSlide>

              {data.map((item: any, index: number) => (
                <>
                  {index > 0 && (
                    <SwiperSlide key={item?.id} style={{ width: 'auto' }}>
                      <PopularZoneEventItem data={item} layout={LAYOUT.VERTICAL} size={SIZE.MEDIUM} />
                    </SwiperSlide>
                  )}
                </>
              ))}
            </Swiper>
            <div className={cx('navigation-btn', 'navigation-btn-prev__event')}>
              <Icon.ArrowLeftWithoutSize />
            </div>
            <div className={cx('navigation-btn', 'navigation-btn-next__event')}>
              <Icon.ArrowRight />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default PopularZoneMobile
