import { useQuery } from 'react-query'
import { isEmpty } from 'lodash'

// Libs
import { fetchPopularProducts } from '@/api/fetchPopularProducts'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

interface IParams {
  params: any
}

export const usePopularProducts = (params: IParams) => {
  const { data, isSuccess } = useQuery([QUERY_KEY.FETCH_POPULAR_PRODUCTS, { ...params }], () => fetchPopularProducts(params), {
    enabled: !isEmpty(params),
  })

  return { popularProductsData: data, isPopularProductsDataSuccess: isSuccess }
}
